import React from "react";

import { Container, Wrapper, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Image from "../components/image";

const IndexPage = (props) => (
  <Layout>
    <Seo
      description="ABuild Consulting Engineers Limited are a consulting practice providing geotechnical and civil expertise in the Wellington and Central North Island areas within New Zealand."
      pathname={props.location.pathname}
    />
    <Container>
      <Wrapper>
        <Box gap={60} className="-center" css={{ maxWidth: "750px" }}>
          <Box className="-textCenter" gap={10}>
            <h1 className="-fontBold">
              Welcome to Abuild™ Consulting Engineers Limited
            </h1>
            <h4>
              ABuild Consulting Engineers Limited are a consulting practice
              providing geotechnical and civil expertise in the Wellington and
              Central North Island areas within New Zealand.
            </h4>
          </Box>
          <Box>
            <p>
              ABuild Consulting Engineers Limited changed ownership January
              2022. Same team, with a few new additions, but at the helm of
              business brings new company directors in Bruce Simms and Shahin
              Ghanooni.
            </p>
            <p>
              Both Bruce and Shahin have been in the engineering consultancy
              business for many years and look forward to growing the current
              business client base while providing services to their current
              client base.
            </p>
            <p>
              Bruce is passionate about people, client relationships, leadership
              and project management. Shahin is passionate about client
              relationships, project delivery and mentoring the team. Having
              owned a business previously overseas, he is excited to share his
              knowledge and experience with Bruce.
            </p>
            <p>Between them, they provide sound technical advice.</p>
          </Box>
          <h3 className="-textCenter">
            Abuild™ Consulting Engineers Ltd strives to remain a specialist
            engineering consultancy that services the needs of a developing
            Region.
          </h3>
          <Image
            filename="Hero_Graphic.jpg"
            alt="Abuild™ Consulting Engineers Limited"
            maxWidth={450}
            centerImage
          />
        </Box>
      </Wrapper>
    </Container>
  </Layout>
);

export default IndexPage;
